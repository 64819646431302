<template>
  <div>
    <h1 class="mb-5">Izberi verzijo:</h1>

    <b-button variant="primary" class="my-2" to="/v1">VAZZ</b-button>
    <br />
    <b-button variant="primary" class="my-2" to="/v2">TOMI</b-button>
    <br />
    <b-button variant="primary" class="my-2" to="/v3">TIM</b-button>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>