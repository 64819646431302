<template>
  <div id="app">
    <b-container class="text-center py-5">
      <router-view></router-view>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.start-container {
}
</style>
