<template>
  <div>
    <h1 class="my-5">Čas je potekel</h1>
    <b-button variant="primary" to="/">NA ZAČETEK</b-button>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>