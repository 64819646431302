<template>
  <div>
    <div v-if="!started" class="start-container">
      <b-button variant="success font-weight-bold px-5 py-2 my-5" @click="start"
        >ZAČNI</b-button
      >
    </div>
    <div v-if="started">
      <div class="countdown">
        <h2>Preostali čas:</h2>
        <h1>
          {{ remainingTime }}
        </h1>
      </div>
      <hr class="my-5" />
      <div v-if="currentQ < questions.length">
        <div class="question" v-for="(q, index) in questions" :key="q.text">
          <div v-show="currentQ === index">
            <h5>Vprašanje:</h5>
            <h3 class="my-3 text-primary">{{ q.text }}</h3>
            <p class="mb-1">Pravilen/i odgovor/i:</p>
            <p v-html="q.answer"></p>
          </div>
        </div>
        <hr class="my-5" />
        <p>Kakšen je bil odgovor?</p>
        <b-button
          variant="success"
          class="font-weight-bold m-2"
          @click="nextQ(true)"
          >PRAVILEN</b-button
        >
        <b-button
          variant="danger"
          class="font-weight-bold m-2"
          @click="nextQ(false)"
          >NAPAČEN</b-button
        >
      </div>
      <div v-if="currentQ >= questions.length">
        <h3 class="mb-4">Odgovorili ste na vsa vprašanja.</h3>
        <b-button variant="success" to="/">NA ZAČETEK</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      started: false,
      remainingTime: 60,
      questions: [
        {
          text: "Katero nagrado je prejel DJ Umek na International Dance Music Award?",
          answer: "Najboljši techno/tech house DJ. ",
        },
        {
          text: "V katerem mestu je bil izveden prvi elektronski koncert?",
          answer: "New York",
        },
        {
          text: "Čigav album je bil inspiracija za ime znanega festivala ULTRA?",
          answer: "Depeche Mode (album Ultra)",
        },
        {
          text: "Kateri DJ je znan po temu, da meče torte v svoje občinstvo?",
          answer: "Steve Aoki",
        },
        {
          text: "Iz katerega mesta izvira techno stil elektronske glasbe?",
          answer: "Detroit (kasneje West Germany - Berlin in Frankfurt)",
        },
        {
          text: "Kdo ima največ Grammyjev v EDM žanru?",
          answer: "SKRILLEX",
        },
        {
          text: "Taigo ni električen avto. Kako pa se imenuje Volkswagen linija elektirčnih avtomobilov? ",
          answer: "ID",
        },
        {
          text: "Ime najbolj znanega techno kluba na svetu se glasi?",
          answer: "Berghain.<br>Priznamo tudi: Tresor ali KitKat ",
        },
        {
          text: "Katera DJka ali DJ zasedba ženskega spola je najvišje na DJMag lestvici?",
          answer: "NERVO",
        },
        {
          text: "Kateri so zgodovinsko gledano trije najbolj razširjeni osnovni žanri elektronske glasbe?",
          answer: "tehno, house in trance ",
        },
        {
          text: "Kdo v elektronski plesni glasbi je prvi v zgodovini razpordal Madison Square Garden?",
          answer: "Swedish House Mafia",
        },
        {
          text: "Kako se imenuje debutanski album Daft punk?",
          answer: "Homework",
        },
        {
          text: "Naštej vsaj 3 svetovne zvezde, s katerimi je sodeloval Fedde Le Grand. ",
          answer: "Madonna, Shaggy, Coldplay, The Chainsmokers in Ida Corr",
        },
      ],
      currentQ: 0,
      countdownInterval: null,
    };
  },

  methods: {
    start() {
      this.started = true;
      this.remainingTime = 60;
      this.countdownInterval = setInterval(() => {
        this.remainingTime--;
        if (this.remainingTime === 0) {
          clearInterval(this.countdownInterval);
          this.$router.push("/cas-potekel");
        }
      }, 1000);
    },

    nextQ(correct) {
      if (correct) {
        this.remainingTime += 10;
      }
      this.currentQ++;
    },
  },
};
</script>

<style lang="scss">
</style>