<template>
  <div>
    <div v-if="!started" class="start-container">
      <b-button variant="success font-weight-bold px-5 py-2 my-5" @click="start"
        >ZAČNI</b-button
      >
    </div>
    <div v-if="started">
      <div class="countdown">
        <h2>Preostali čas:</h2>
        <h1>
          {{ remainingTime }}
        </h1>
      </div>
      <hr class="my-5" />
      <div v-if="currentQ < questions.length">
        <div class="question" v-for="(q, index) in questions" :key="q.text">
          <div v-show="currentQ === index">
            <h5>Vprašanje:</h5>
            <h3 class="my-3 text-primary">{{ q.text }}</h3>
            <p class="mb-1">Pravilen/i odgovor/i:</p>
            <p>
              {{ q.answer }}
            </p>
          </div>
        </div>
        <hr class="my-5" />
        <p>Kakšen je bil odgovor?</p>
        <b-button
          variant="success"
          class="font-weight-bold m-2"
          @click="nextQ(true)"
          >PRAVILEN</b-button
        >
        <b-button
          variant="danger"
          class="font-weight-bold m-2"
          @click="nextQ(false)"
          >NAPAČEN</b-button
        >
      </div>
      <div v-if="currentQ >= questions.length">
        <h3 class="mb-4">Odgovorili ste na vsa vprašanja.</h3>
        <b-button variant="success" to="/">NA ZAČETEK</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      started: false,
      remainingTime: 90,
      questions: [
        {
          text: "Katerega leta so premierno nastopili Pankrti?",
          answer: "1977 (priznamo tudi 3 leta plus/minus)",
        },
        {
          text: "V katerem mestu so s prepovedanim koncertom začeli Laibach?",
          answer: "Trbovlje",
        },
        {
          text: "Katera skladba velja za najbolj predvajano rock skladbo vseh časov?",
          answer: "Bohemian Rhapsody - The Queen",
        },
        {
          text: "Kdo izmed dvojca Lennon/Mccartney je napisal skladbo Let it be?",
          answer: "McCartney",
        },
        {
          text: "V kateri podžanr rocka uvrščamo Pearl Jam?",
          answer: "Grunge",
        },
        {
          text: "Iz katere države so AC/DC?",
          answer: "Avstralije",
        },
        {
          text: "Kako bi s tremi besedami opisal novi Taigo?",
          answer: "Po lastni volji",
        },
        {
          text: "Kateri band poje verz “Please allow me to introduce myself I'm a man of wealth and taste”?",
          answer: "The Rolling Stones  (Sympathy for the devil)",
        },
        {
          text: "Kateri rock pevec se je rodil z imenom Farrokh Bulsara?",
          answer: "Freddie Mercury",
        },
        {
          text: "Kdo izmed naštetih ni nastopil na legendarnem koncertu Live Aid? Led Zeppelin, Queen, Status Quo, Metallica",
          answer: "Metallica",
        },
        {
          text: "Kateri rocker je bil nominiran za Oskarja, Grammyja, Golden Globe in Nobelovo nagrado?",
          answer: "Bono",
        },
        {
          text: "V katerem desetletju je izšla skladba Johnny B. Goode?",
          answer: "50-ta (1958)",
        },
        {
          text: "Kateri rock pevec je znan pod vzdevkom King of Rock?",
          answer: "Elvis Presley",
        },
      ],
      currentQ: 0,
      countdownInterval: null,
    };
  },

  methods: {
    start() {
      this.started = true;
      this.remainingTime = 90;
      this.countdownInterval = setInterval(() => {
        this.remainingTime--;
        if (this.remainingTime === 0) {
          clearInterval(this.countdownInterval);
          this.$router.push("/cas-potekel");
        }
      }, 1000);
    },

    nextQ(correct) {
      if (correct) {
        this.remainingTime += 10;
      }
      this.currentQ++;
    },
  },
};
</script>

<style lang="scss">
</style>