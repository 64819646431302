<template>
  <div>
    <div v-if="!started" class="start-container">
      <b-button variant="success font-weight-bold px-5 py-2 my-5" @click="start"
        >ZAČNI</b-button
      >
    </div>
    <div v-if="started">
      <div class="countdown">
        <h2>Preostali čas:</h2>
        <h1>
          {{ remainingTime }}
        </h1>
      </div>
      <hr class="my-5" />
      <div v-if="currentQ < questions.length">
        <div class="question" v-for="(q, index) in questions" :key="q.text">
          <div v-show="currentQ === index">
            <h5>Vprašanje:</h5>
            <h3 class="my-3 text-primary">{{ q.text }}</h3>
            <p class="mb-1">Pravilen/i odgovor/i:</p>
            <p>
              {{ q.answer }}
            </p>
          </div>
        </div>
        <hr class="my-5" />
        <p>Kakšen je bil odgovor?</p>
        <b-button
          variant="success"
          class="font-weight-bold m-2"
          @click="nextQ(true)"
          >PRAVILEN</b-button
        >
        <b-button
          variant="danger"
          class="font-weight-bold m-2"
          @click="nextQ(false)"
          >NAPAČEN</b-button
        >
      </div>
      <div v-if="currentQ >= questions.length">
        <h3 class="mb-5">Odgovorili ste na vsa vprašanja.</h3>
        <b-button variant="success" to="/">NA ZAČETEK</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      started: false,
      remainingTime: 90,
      questions: [
        {
          text: "Kateri raper je leta 2001 organiziral prvi slovenski freestyle rap battle?",
          answer: "6pack čukur",
        },
        {
          text: "Kje poleg New Yorka ima rap še izvorno točko?",
          answer: "JAMAJKA",
        },
        {
          text: "Naštej vsaj 3 skladbe iz Ali Enovega legendarnega albuma Leva scena.",
          answer:
            "Leva scena, Stremetzsky, Sirni & mesni, Za koga?, Štala, Ne vem če spim in sanjam, Plun k delavc, TNB, Takšnega dneva pa še ne …",
        },
        {
          text: "Kako se imenuje odmeven album slovenske rap zasedbe Pasji Kartel?",
          answer: "Kartelova teorija",
        },
        {
          text: "Kako se je imenovala glasbena zasedba, ki je izdala album Včasih smučam hit (včasih pa počas)?",
          answer: "Košir Rap Team",
        },
        {
          text: "Kateri rapper ima največ albumov nagrajenih z Grammyjem?",
          answer: "Eminem",
        },
        {
          text: "Kateri danes znan in nagrajen raper je v otroštvu zelo jecljal?",
          answer: "Kendrick Lamar",
        },
        {
          text: "Čigava verz je: I am not a businessman. I am a business. Man. ",
          answer: "Jay-Z",
        },
        { text: "Čigav album je: Get Rich or Die Tryin’", answer: "50 Cent" },
        {
          text: "Kateri model Volkswagen je preuredil in vozil Will.I.Am?",
          answer: "VW Beetle / Hrošč",
        },
        {
          text: "Kaj je bil prvi povod za rivalstvo Tupac - Notorious B.I.G?",
          answer: "TuPac je Biggieja zavrnil, da bi meneđiral njegovo kariero",
        },
        {
          text: "Kdo je najbolj znan rapper po uporabi auto-tune-a?",
          answer: "T-Pain",
        },
        {
          text: "Soavtor katere Will Smithove pesmi nagrajene z Grammyjem pesmi je Nas?",
          answer: "'Gettin' Jiggy Wit It'",
        },
        {
          text: "Kakšen alias si je nadel TuPac na začetku kariere v Baltimoru?",
          answer: "MC New York",
        },
      ],
      currentQ: 0,
      countdownInterval: null,
    };
  },

  methods: {
    start() {
      this.started = true;
      this.remainingTime = 90;
      this.countdownInterval = setInterval(() => {
        this.remainingTime--;
        if (this.remainingTime === 0) {
          clearInterval(this.countdownInterval);
          this.$router.push("/cas-potekel");
        }
      }, 1000);
    },

    nextQ(correct) {
      if (correct) {
        this.remainingTime += 10;
      }
      this.currentQ++;
    },
  },
};
</script>

<style lang="scss">
</style>