import Vue from 'vue'
import VueRouter from 'vue-router'
import Entry from '../views/Entry.vue'
import v1 from '../views/v1.vue'
import v2 from '../views/v2.vue'
import v3 from '../views/v3.vue'
import TimeUp from '../views/TimeUp.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Entry',
        component: Entry,
    },
    {
        path: '/v1',
        name: 'v1',
        component: v1,
    },
    {
        path: '/v2',
        name: 'v2',
        component: v2,
    },
    {
        path: '/v3',
        name: 'v3',
        component: v3,
    },
    {
        path: '/cas-potekel',
        name: 'TimeUp',
        component: TimeUp,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
